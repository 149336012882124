import 'react-app-polyfill/stable'
import 'url-search-params-polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import { matchPath } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import './index.css'
import App from './App'
import { browsers } from 'supported-browsers'
import UnsupportedBrowser from './components/UnsupportedBrowser/UnsupportedBrowser'
import { environment } from '@environments/environment'
import { AppEnvEnum } from '@typedef/Environment'
import { sentryDsn } from '@constants/sentry'
import browserHistory from '@utils/browserHistory'
import { chatRoutes } from '@constants/routes'

const root = document.getElementById('root')

if (environment.REACT_APP_ENV !== AppEnvEnum.PROD) {
  const favicon = document.getElementById('favicon') as HTMLLinkElement
  favicon.href =
    environment.REACT_APP_ENV === AppEnvEnum.STAGING
      ? '/favicon_staging.ico?v2'
      : '/favicon_dev.ico?v2'
}

if (environment.REACT_APP_ENV !== AppEnvEnum.DEV) {
  Sentry.init({
    dsn: sentryDsn,
    environment: 'staging',
    release: environment.REACT_APP_SENTRY_RELEASE_VERSION,
    integrations: [
      Sentry.reactRouterV5BrowserTracingIntegration({
        history: browserHistory,
        routes: chatRoutes,
        matchPath,
      }),
    ],
    tracesSampleRate: 1,
  })
}

const userAgent = window.navigator.userAgent

if (browsers.test(userAgent)) {
  ReactDOM.render(<App />, root)
} else {
  console.error(`User agent not supported: ${userAgent}`)
  ReactDOM.render(<UnsupportedBrowser />, root)
}

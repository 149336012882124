import { TabularDataTab, UserOrganization } from '@pactum/core-backend-types'
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import {
  createApi,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/dist/query/react'
import { shouldSkipChatStep } from '@utils/chat'
import { environment } from 'environments/environment'
import { AppDispatch } from 'store'
import { baseQueryWithAuthAndRetry } from 'store/api/baseQuery'
import {
  ChatParamsResponse,
  ContactConfirmationResponse,
  ContactConfirmationSubmitRequest,
  FaqContent,
  HelpRequestParams,
  LinkAuthInfo,
  NegotiationDeadlineExtensionResponse,
  NegotiationParamsResponse,
  NextStepRequestParams,
  NextStepResponse,
  SimulateResponse,
  SimulationStatusResponse,
  SimulatorTestRequestParams,
  TabularDataSubmitParams,
  TabularDataUpdateParams,
} from 'store/api/types'
import { clearTimeoutError, setTimeoutError } from 'store/slices/chat'
import { DebugState, SimulatorTest } from 'typedef/debug-toolbar'

export const baseApi = createApi({
  baseQuery: baseQueryWithAuthAndRetry,
  tagTypes: ['ContactConfirmation'],
  endpoints: (builder) => ({
    getLinkAuthInfo: builder.query<LinkAuthInfo, string>({
      query: (linkToken) => `chats/${linkToken}/auth-info`,
    }),
    getChatParams: builder.query<
      ChatParamsResponse,
      {
        token: string
        stateId?: string
        stepId?: string
        stepCount?: string
        brandingUuid?: string
      }
    >({
      query: ({ token, stateId, stepId, stepCount, brandingUuid }) => ({
        url: `models/${token}`,
        params: { stateId, stepId, stepCount, brandingUuid },
      }),
    }),
    getFaqContents: builder.query<
      FaqContent[],
      {
        token: string
        brandingUuid?: string
      }
    >({
      query: ({ token, brandingUuid }) => ({
        url: `/faq/${token}`,
        params: brandingUuid
          ? {
              brandingUuid,
            }
          : {},
      }),
    }),
    getNextStep: builder.mutation<NextStepResponse, NextStepRequestParams>({
      queryFn: async (
        { token, stateId, stepId, value, delay, accessKey, stepInput },
        api,
        _extraOptions,
        baseQuery,
      ) => {
        const appDispatch = api.dispatch as AppDispatch
        const connectivityTimeoutId = setTimeout(() => {
          appDispatch(setTimeoutError())
        }, 10_000)

        const requestStartTime = Date.now()

        let result: QueryReturnValue<NextStepResponse, FetchBaseQueryError, FetchBaseQueryMeta>
        try {
          result = (await baseQuery({
            url: `chats/${token}`,
            method: 'POST',
            body: { stateId, stepId, value, accessKey, stepInput },
            // Required cast, RTK is not able to infer the ResultType (NextStepResponse here)
          })) as QueryReturnValue<NextStepResponse, FetchBaseQueryError, FetchBaseQueryMeta>
        } finally {
          clearTimeout(connectivityTimeoutId)
        }
        appDispatch(clearTimeoutError())

        if (result.data && delay && delay > 0) {
          if (result.data.steps.length === 1 && shouldSkipChatStep(result.data.steps[0])) {
            return result
          }
          // For successful request, delay receiving it
          return new Promise((resolve) => {
            const requestTime = Date.now() - requestStartTime
            setTimeout(() => resolve(result), Math.max(delay - requestTime, 0))
          })
        }

        return result
      },
    }),
    tabularDataUpdate: builder.mutation<
      { tabularDataTab: TabularDataTab },
      TabularDataUpdateParams
    >({
      query: ({ token, stepId, stateId, tabId, updatedRows }) => ({
        url: `chats/${token}/steps/${stepId}/tabular-data`,
        method: 'PATCH',
        params: { stateId },
        body: { tabId, updatedRows },
      }),
    }),
    tabularDataSubmit: builder.mutation<
      { tabularDataError?: string; tabularData?: TabularDataTab[] },
      TabularDataSubmitParams
    >({
      query: ({ token, stepId, stateId }) => ({
        url: `chats/${token}/steps/${stepId}/tabular-data`,
        method: 'POST',
        params: { stateId },
      }),
    }),
    requestHelp: builder.mutation<unknown, HelpRequestParams>({
      query: ({ token, stateId, stepId, message, lastStepMessage }) => ({
        url: `chats/${token}/help`,
        method: 'POST',
        params: { stateId },
        body: { stepId, message, lastStepMessage },
      }),
    }),
    simulate: builder.mutation<SimulateResponse, SimulatorTestRequestParams>({
      query: ({ negotiationId, flowId, inputs, modelAttributes }) => ({
        url: `${environment.REACT_APP_API_URL}/chats/debug/simulator/simulate/${negotiationId}`,
        method: 'POST',
        body: { flowId, inputs, modelAttributes },
      }),
    }),
    getSimulationStatus: builder.query<SimulationStatusResponse, number>({
      query: (jobId: number) =>
        `${environment.REACT_APP_API_URL}/chats/debug/simulator/status/${jobId}`,
    }),
    getDebug: builder.query<DebugState, string>({
      query: (stateId: string) => `${environment.REACT_APP_API_URL}/chats/debug/${stateId}`,
    }),
    getSimulatorTest: builder.query<SimulatorTest, string>({
      query: (stateId) => `${environment.REACT_APP_API_URL}/chats/debug/${stateId}/simulator-test`,
    }),
    getContactConfirmation: builder.query<ContactConfirmationResponse, string>({
      query: (uuid: string) => `contact-confirmations/${uuid}`,
      providesTags: ['ContactConfirmation'],
    }),
    logCaptchaError: builder.mutation<
      void,
      { uuid: string; additionalInfo: Record<string, string> }
    >({
      query: ({ uuid, additionalInfo }) => ({
        url: `contact-confirmations/${uuid}/log-captcha-result`,
        method: 'PUT',
        body: additionalInfo,
      }),
    }),
    registerConfirmationPageVisit: builder.mutation<void, string>({
      query: (uuid: string) => ({
        url: `contact-confirmations/${uuid}/register-visit`,
        method: 'PUT',
      }),
    }),
    submitContactConfirmation: builder.mutation<
      void,
      { uuid: string; request: ContactConfirmationSubmitRequest; captchaToken: string }
    >({
      query: ({ uuid, request, captchaToken }) => ({
        url: `contact-confirmations/${uuid}`,
        method: 'PUT',
        body: request,
        headers: {
          'X-Pactum-Captcha-Token': captchaToken,
        },
      }),
      invalidatesTags: (result, error) => (error ? [] : ['ContactConfirmation']),
    }),
    getUserOrganizations: builder.query<UserOrganization[], void>({
      query: () => `user/organizations`,
    }),
    getNegotiationParams: builder.query<NegotiationParamsResponse, string>({
      query: (uuid) => ({
        url: `negotiations/${uuid}/params`,
      }),
    }),
    extendNegotiationDeadline: builder.mutation<NegotiationDeadlineExtensionResponse, string>({
      query: (uuid) => ({
        url: `negotiations/${uuid}/extend-deadline`,
        method: 'PUT',
      }),
    }),
  }),
})

export const {
  useGetChatParamsQuery,
  useGetFaqContentsQuery,
  useGetDebugQuery,
  useLazyGetSimulatorTestQuery,
  useGetNextStepMutation,
  useGetSimulationStatusQuery,
  useRequestHelpMutation,
  useSimulateMutation,
  useGetContactConfirmationQuery,
  useLogCaptchaErrorMutation,
  useRegisterConfirmationPageVisitMutation,
  useSubmitContactConfirmationMutation,
  useTabularDataUpdateMutation,
  useTabularDataSubmitMutation,
  useGetNegotiationParamsQuery,
  useExtendNegotiationDeadlineMutation,
} = baseApi

import React from 'react'

import { FullPageError } from '@components/FullPageError/FullPageError'
import {
  DEFAULT_SUPPORT_EMAIL,
  ERROR_TRANSLATION_KEYS,
  getDefaultErrorMessage,
} from '@constants/errors'
import { parseQueryError, QueryError } from '@store/api'
import useTranslations from '../localisation/useTranslations'
import AuthErrorPage from '@pages/AuthErrorPage'
import { Box, Typography } from '@mui/material'
import { useChatParams } from '@store/selectors'

interface Props {
  queryError: QueryError
}

const ChatErrorPage = ({ queryError }: Props): JSX.Element => {
  const localise = useTranslations()
  const {
    status,
    errorInternalCode,
    supportEmail: supportEmailFromErrorMetadata,
  } = parseQueryError(queryError)
  const chatParams = useChatParams()
  const supportEmail =
    chatParams?.supportEmail || supportEmailFromErrorMetadata || DEFAULT_SUPPORT_EMAIL

  if (status === 403) {
    return <AuthErrorPage />
  }

  const errorMessage = (): string => {
    if (errorInternalCode !== undefined) {
      return localise(ERROR_TRANSLATION_KEYS[errorInternalCode])
    }

    return getDefaultErrorMessage(supportEmail)
  }

  // TODO: needs localisation
  const errorTitle = (): string => {
    if (status === 'FETCH_ERROR') {
      return 'Network error'
    } else if (errorInternalCode === 'NEGOTIATION_EXPIRED') {
      return 'Negotiation expired'
    } else if (errorInternalCode === 'NEGOTIATION_DELETED') {
      return 'Negotiation deleted'
    } else if (errorInternalCode === 'STATE_MISSING') {
      return 'This negotiation has not yet been started by the supplier.'
    } else if (errorInternalCode === 'NEGOTIATION_NOT_FOUND') {
      return '404 Not found'
    } else {
      return 'Unknown error'
    }
  }
  const isNetworkError = () => errorTitle() === 'Network error'

  return (
    <FullPageError title={errorTitle()} supportEmail={supportEmail}>
      {isNetworkError() ? (
        <NetworkError errorText={localise(ERROR_TRANSLATION_KEYS['NETWORK_ERROR'])} />
      ) : (
        <p>{errorMessage()}</p>
      )}
    </FullPageError>
  )
}

const NetworkError = ({ errorText }: { errorText: string }) => {
  return (
    <div style={{ paddingTop: 7 }}>
      <Typography variant='body1' gutterBottom style={{ lineHeight: '1.1' }}>
        {errorText.split('\n').map((line, index) => (
          <Box key={index} pb={1}>
            {/^\d/.test(line.trimStart().charAt(0)) ? ( // if line starts with a number add padding
              <Typography pl={2}>{line}</Typography>
            ) : (
              <Typography>{line}</Typography>
            )}
          </Box>
        ))}
      </Typography>
    </div>
  )
}

export default ChatErrorPage

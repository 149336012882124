import React from 'react'
import { FullPageError } from '../FullPageError/FullPageError'
import { DEFAULT_SUPPORT_EMAIL } from '@constants/errors'
import { reportError } from '@utils/errorReporting'

const UnsupportedBrowser = (): JSX.Element => {
  reportError('Unsupported browser')

  return (
    <FullPageError title={'Unsupported Browser'} supportEmail={DEFAULT_SUPPORT_EMAIL}>
      <p>
        We are sorry, but your browser is not supported. Please use a more modern browser to open
        this link.
      </p>
    </FullPageError>
  )
}

export default UnsupportedBrowser

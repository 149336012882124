import { ExpandMore } from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  alpha,
  Box,
  Divider,
  styled,
  Typography,
} from '@mui/material'
import { ChatFaqQuestion } from '@pactum/core-backend-types'
import React from 'react'
import ReactMarkdown from 'react-markdown'

interface Props {
  questionsAnswers: ChatFaqQuestion[]
  enableHtmlContent?: boolean
}

export const HelpDrawerFAQ = ({ questionsAnswers, enableHtmlContent }: Props): JSX.Element => {
  return (
    <FAQContainer>
      {questionsAnswers.map((questionAnswer) => (
        <StyledAccordion
          key={`${questionAnswer.question}-${questionAnswer.answerMarkdown.substring(0, 10)}`}
        >
          <StyledAccordionSummary expandIcon={<ExpandMore />}>
            <QuestionText>{questionAnswer.question}</QuestionText>
          </StyledAccordionSummary>
          <Divider variant='middle' />
          <StyledAccordionDetails>
            <Typography component='span' sx={{ fontSize: '0.875em', lineHeight: '1.71em' }}>
              {!enableHtmlContent && (
                <ReactMarkdown linkTarget='_blank'>{questionAnswer.answerMarkdown}</ReactMarkdown>
              )}
              {enableHtmlContent && (
                <div dangerouslySetInnerHTML={{ __html: questionAnswer.answerMarkdown }}></div>
              )}
            </Typography>
          </StyledAccordionDetails>
        </StyledAccordion>
      ))}
    </FAQContainer>
  )
}

const FAQContainer = styled(Box)({
  marginTop: 8,
  marginBottom: 12,
  '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
    '&': {
      flexShrink: 0,
    },
  },
})

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  '&.Mui-expanded': {
    margin: '0 0 8px',
  },
  '&:before': {
    display: 'none',
  },
  margin: '0 0 8px',
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: alpha(theme.palette.primary.main, 0.8),
  boxShadow: 'none',
  borderRadius: 3,
  backgroundColor: 'transparent',
}))

const StyledAccordionSummary = styled(AccordionSummary)({
  fontWeight: 'bold',
  '&.Mui-expanded': {
    minHeight: 48,
  },
  '.MuiAccordionSummary-content.Mui-expanded': {
    margin: '12px 0',
  },
})

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: '8px 20px 0',
  'ul, ol': {
    paddingLeft: 20,
  },
  p: {
    marginTop: 0,
  },
  '*': {
    maxWidth: '100%',
  },
  a: {
    color: theme.palette.primary.dark,
    fontWeight: 'bolder',
  },
}))

const QuestionText = styled(Typography)({
  fontWeight: 'bold',
})

import { SerializedError } from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react'
import { Search, SimpleTokenizer, StopWordsTokenizer } from 'js-search'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import ClearIcon from '@mui/icons-material/Clear'
import SearchIcon from '@mui/icons-material/Search'
import { Alert, Fade, InputAdornment, styled, TextField, Typography } from '@mui/material'
import { ChatFaqQuestion } from '@pactum/core-backend-types/dist/core-backend-api/project/project-config'

import { ChatUrlParams } from '@constants/routes'
import { ChatDataTestId } from '@typedef/chatSteps'
import { DEFAULT_SUPPORT_EMAIL, getDefaultErrorMessage } from 'constants/errors'
import useTranslations from 'localisation/useTranslations'
import { useAppSelector } from 'store'
import {
  HelpRequestParams,
  isCoreBackendApiError,
  useGetFaqContentsQuery,
  useRequestHelpMutation,
} from 'store/api'
import { useChatParams } from 'store/selectors'
import { HelpDrawerFAQ } from './HelpDrawerFAQ'
import { MessageBox } from './MessageBox'
import { ActionButton, ActionButtonWrap, Content } from './styled'

import { useDebounce } from '@hooks/useDebounce'
import { isQuerySuccessResponse } from '@store/api/error'
import { cyrb53a } from '@utils/helpers'
import { Spinner } from './Spinner'

interface Props {
  faqEnabled: boolean
}

// cyrb53a hashed values of workspace tags
// use `console.log(cyrb53a('your-workspace-tag'))` to get the hash
const workspaceHashes = new Set([6714143605379481])

export const HelpDrawerContent = ({ faqEnabled }: Props): JSX.Element => {
  const [message, setMessage] = useState('')
  const [messageSent, setMessageSent] = useState(false)
  const [answerNotFoundClicked, setAnswerNotFoundClicked] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [searchIsLoading, setSearchIsLoading] = useState(false)
  const localise = useTranslations()

  const { token } = useParams<ChatUrlParams>()
  const chatParams = useChatParams()
  const chatSteps = useAppSelector((state) => state.chat.steps)
  const [requestHelp, { isLoading, error: helpError }] = useRequestHelpMutation()

  const enableFaqTemplates =
    !!chatParams?.projectTag && workspaceHashes.has(cyrb53a(chatParams?.projectTag))

  const faqQuery = useGetFaqContentsQuery(
    {
      token,
    },
    {
      skip: !enableFaqTemplates,
    },
  )

  // Base FAQ content based on templates or chatParams
  const qaContent: ChatFaqQuestion[] = useMemo(() => {
    if (enableFaqTemplates && faqQuery.data) {
      return faqQuery.data.map((faq) => ({
        question: faq.question,
        answerMarkdown: faq.answer,
      }))
    }

    if (!enableFaqTemplates && chatParams?.questionsAnswers) {
      return chatParams.questionsAnswers
    }

    return []
  }, [chatParams?.questionsAnswers, enableFaqTemplates, faqQuery.data])

  // Search index setup
  const basicTokenizer = useRef<SimpleTokenizer>(new SimpleTokenizer())
  const index = useRef<Search | null>(null)

  useEffect(() => {
    if (!qaContent) {
      index.current = null
      return
    }
    const search = new Search('id')
    search.tokenizer = new StopWordsTokenizer({
      tokenize(text: string): Array<string> {
        return basicTokenizer.current.tokenize(text.normalize('NFD').replace(/\p{Diacritic}/gu, ''))
      },
    })
    search.addIndex('question')
    search.addIndex('answer')
    search.addDocuments(
      qaContent.map((qa, index) => ({
        id: index,
        question: qa.question,
        answer: qa.answerMarkdown,
      })),
    )
    index.current = search
  }, [qaContent])

  // Memoized displayedFaqItems based on search
  const displayedFaqItems = useMemo(() => {
    if (!qaContent?.length) return []

    const term = searchTerm.trim()
    if (!term || !index.current) {
      return qaContent
    }

    const results = index.current.search(term) as { id: number }[]
    return results.reduce<ChatFaqQuestion[]>((acc, { id }) => {
      const item = qaContent[id]
      if (item) {
        acc.push(item)
      }
      return acc
    }, [])
  }, [qaContent, searchTerm])

  const startSearching = (e: React.ChangeEvent<HTMLInputElement>) => {
    const term = e.target.value
    setSearchTerm(term)
    setSearchIsLoading(true)
    doSearchDebounced(term)
  }

  const doSearch = (searchBoxValue: string) => {
    setSearchTerm(searchBoxValue)
    setSearchIsLoading(false)
  }

  const doSearchDebounced = useDebounce(doSearch, 500)

  const messageSendClicked = async () => {
    const lastStepObject = chatSteps[chatSteps.length - 1]
    const helpRequestParams: HelpRequestParams = {
      token,
      stateId: chatParams?.stateId,
      stepId: lastStepObject?.id,
      message: message,
    }

    const response = await requestHelp(helpRequestParams)

    if (isQuerySuccessResponse(response)) {
      setMessageSent(true)
    }
  }

  if (messageSent) {
    return <MessageSent setMessageSent={setMessageSent} />
  }

  return (
    <>
      <Title variant='h5' component='h2' paragraph>
        {localise('helpDrawer.title')}
      </Title>
      {(enableFaqTemplates || faqEnabled) && qaContent ? (
        <>
          <Content variant='body1' component='p'>
            {localise('helpDrawer.faqBody')}
          </Content>
          <TextField
            margin={'normal'}
            placeholder={localise('helpDrawer.searchPrompt')}
            InputProps={{
              startAdornment: (
                <InputAdornment position={'start'}>
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position={'end'}>
                  {!!searchTerm && (
                    <ClearIcon onClick={() => doSearch('')} sx={{ cursor: 'pointer' }} />
                  )}
                </InputAdornment>
              ),
            }}
            value={searchTerm}
            variant={'standard'}
            onChange={startSearching}
            data-testid={ChatDataTestId.HELP_FAQ_SEARCH_INPUT}
          />
          {searchIsLoading ? (
            <Spinner />
          ) : (
            <HelpDrawerFAQ
              enableHtmlContent={enableFaqTemplates}
              questionsAnswers={displayedFaqItems}
            />
          )}

          {!answerNotFoundClicked ? (
            <ActionButtonWrap>
              <ActionButton
                onClick={() => setAnswerNotFoundClicked(true)}
                variant='contained'
                color='secondary'
                disableElevation
              >
                {localise('helpDrawer.faqSendEmailButton')}
              </ActionButton>
            </ActionButtonWrap>
          ) : null}
        </>
      ) : null}

      {/* FAQ not enabled - just show message box */}
      {(!faqEnabled || answerNotFoundClicked) && (
        <MessageBox
          sendingMessage={isLoading}
          setMessage={setMessage}
          onMessageSendClicked={messageSendClicked}
        />
      )}
      {helpError && <HelpRequestError helpError={helpError} />}
    </>
  )
}

const MessageSent = ({ setMessageSent }: { setMessageSent: (sent: boolean) => void }) => {
  const localise = useTranslations()

  return (
    <>
      <Title variant='h5' component='h2' paragraph>
        {localise('helpDrawer.thankYou')}
      </Title>
      <Content variant='body2' component='p'>
        {localise('helpDrawer.messageSentText')}
      </Content>
      <ActionButtonWrap>
        <ActionButton
          variant='outlined'
          color='primary'
          disableElevation
          onClick={() => setMessageSent(false)}
        >
          {localise('helpDrawer.askAnotherQuestion')}
        </ActionButton>
      </ActionButtonWrap>
    </>
  )
}

const HelpRequestError = ({ helpError }: { helpError: FetchBaseQueryError | SerializedError }) => {
  const { supportEmail } = useChatParams() ?? {}
  let errorMessage = getDefaultErrorMessage(supportEmail || DEFAULT_SUPPORT_EMAIL)

  if (isCoreBackendApiError(helpError) && helpError.status < 500) {
    errorMessage = helpError.data.message
  }

  return (
    <Fade in={true}>
      <ErrorMessage data-testid={ChatDataTestId.ALERT_HELP_ERROR} severity='error'>
        {errorMessage}
      </ErrorMessage>
    </Fade>
  )
}

const ErrorMessage = styled(Alert)({
  position: 'absolute',
  left: 0,
  right: 0,
  bottom: 0,
  maxWidth: 720,
  margin: '0 auto',
})

const Title = styled(Typography)({
  fontWeight: 'bold',
  fontSize: '1.1875em',
}) as typeof Typography

import React from 'react'
import { Element } from 'domhandler'
import DOMPurify from 'dompurify'
import parse from 'html-react-parser'

export const SvgReplace = (element: Element) => {
  const width = element.attribs['width']
  const height = element.attribs['height']
  const encodedSvgContent = element.attribs['data-pactum-svg']

  if (!encodedSvgContent || !width || !height) {
    return null
  }

  const decodedSvgContent = atob(encodedSvgContent)
  const sanitizedSvgContent = DOMPurify.sanitize(
    `<svg width="${width}" height="${height}">${decodedSvgContent}</svg>`,
    {
      USE_PROFILES: { svg: true },
    },
  )

  return <>{parse(sanitizedSvgContent)}</>
}
